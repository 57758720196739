<template>
    <div class="app-layout">
        <!-- If user's agent is Internet Explorer, we show the stub for IE -->
        <IEpage v-if="isIE"></IEpage>

        <VAppLayout v-else-if="isVuexBooted" :isSnowEnabled="isSnowEnabled">
            <Splitpanes class="page" @resized="saveMenuPaneSize">
                <Pane class="page__menu-pane" :size="menuPaneSize" max-size="45" min-size="15">
                    <RouterView name="menu"></RouterView>
                </Pane>
                <Pane>
                    <Splitpanes @resized="saveRequestPaneSize">
                        <Pane>
                            <RouterView></RouterView>
                        </Pane>
                        <RouterView name="sharedPanel" :requestPaneSize="requestPaneSize"></RouterView>
                    </Splitpanes>
                </Pane>
            </Splitpanes>
            <VImagePopup></VImagePopup>
            <VErrorModal></VErrorModal>
            <VRequestPauseModal></VRequestPauseModal>
            <VRequestChangeServiceModal></VRequestChangeServiceModal>
            <VRequestChangeExecutorModal></VRequestChangeExecutorModal>
            <VRequestSendToBitrix24Modal></VRequestSendToBitrix24Modal>
            <VRequestSolutionModal></VRequestSolutionModal>
            <VRequestAcceptWorkModal></VRequestAcceptWorkModal>
        </VAppLayout>

        <!-- While vuex is loading, we show the stub -->
        <div class="loading-app loading-app--center" v-else>
            <VSpinner></VSpinner>

            <!-- Needs for the auth routes -->
            <template v-if="isSignInPage">
                <RouterView></RouterView>
            </template>
        </div>

        <VRequestCreateModal></VRequestCreateModal>
    </div>
</template>
<script>
// Components
import VImagePopup from './views/components/VImagePopup/VImagePopup.vue';
import VErrorModal from './views/modals/VErrorModal.vue';
import VRequestPauseModal from './views/modals/VRequestPauseModal.vue';
import VRequestChangeServiceModal from './views/modals/VRequestChangeServiceModal.vue';
import VRequestChangeExecutorModal from './views/modals/VRequestChangeExecutorModal.vue';
import VRequestSendToBitrix24Modal from './views/modals/VRequestSendToBitrix24Modal.vue';
import VRequestSolutionModal from './views/modals/VRequestSolutionModal.vue';
import VRequestAcceptWorkModal from './views/modals/VRequestAcceptWorkModal.vue';
import VRequestCreateModal from './views/modals/VRequestCreateModal.vue';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import ModalNames from '@/js/enums/ModalNames';

import VSpinner from '@/views/components/VProgress/VSpinner.vue';
import VAppLayout from './views/components/VApp';
import { isVuexBooted } from './store';

import IEpage from './views/pages/ErrorPage/IE';

// Other
import Vue from 'vue';
import oidc from './vue-oidc-client';
import Component from 'vue-class-component';
import './scss/style.scss';

@Component({
    components: {
        IEpage,
        VSpinner,
        VAppLayout,
        VImagePopup,
        Splitpanes,
        Pane,

        VErrorModal,
        VRequestPauseModal,
        VRequestChangeServiceModal,
        VRequestChangeExecutorModal,
        VRequestSendToBitrix24Modal,
        VRequestAcceptWorkModal,
        VRequestSolutionModal,
        VRequestCreateModal,
    },
})
class App extends Vue {
    isVuexBooted = false;
    menuPaneSize = 20;
    requestPaneSize = 45;
    isSnowEnabled = true;

    get isIE() {
        const userAgent = window.navigator.userAgent.toLowerCase();

        return /trident/gi.test(userAgent) || /msie/gi.test(userAgent);
    }

    get isSignInPage() {
        return this.$route.name === 'signinwin-sso';
    }

    vuexBootedHandler() {
        this.isVuexBooted = true;

        const showCreateRequestModal = Object.values(this.$route.query).join('');
        if (showCreateRequestModal) {
            this.showRequestCreateModal();
        }

        oidc.startSilentRenew();
    }

    saveRequestPaneSize(event) {
        if (event[1]) {
            this.requestPaneSize = event[1].size;
            localStorage.setItem('requestPaneSize', this.requestPaneSize);
        }
    }

    saveMenuPaneSize(event) {
        this.menuPaneSize = event[0].size;
        localStorage.setItem('menuPaneSize', this.menuPaneSize);
    }

    async showRequestCreateModal() {
        const request = await this.$showModal(ModalNames.REQUEST_CREATE);

        this.$router.push({
            name: 'request',
            params: {
                method: 'outbox',
                id: request.id,
            },
        });
    }

    handleToggleSnow(isEnabled){
        this.isSnowEnabled = isEnabled;
        localStorage.setItem('toggleSnow', this.isSnowEnabled);
    }

    created() {
        this.isVuexBooted = isVuexBooted;
        this.$bus.on('Vuex::Booted', this.vuexBootedHandler);
        this.$bus.on('toggleSnow', this.handleToggleSnow);
        this.menuPaneSize = Number(localStorage.getItem('menuPaneSize')) || 20;
        this.requestPaneSize = Number(localStorage.getItem('requestPaneSize')) || 45;
        this.isSnowEnabled = localStorage.getItem('toggleSnow') ? JSON.parse(localStorage.getItem('toggleSnow')) : true;
    }
}

export default App;
</script>
<style lang="scss">
.loading-app {
    display: flex;
    width: 100%;
    height: 1px;
    min-height: 100%;

    &--center {
        align-items: center;
        justify-content: center;
    }
}
.page {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    &__menu-pane {
        box-shadow: 0 0 0.6875rem 0 rgba(0, 0, 0, 0.07);
    }
}
.splitpanes--vertical .splitpanes__pane {
    transition: none;
}
.splitpanes__pane {
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.splitpanes__splitter {
    position: relative;
    padding: 0 8px;
    margin: 0 -8px;
    z-index: 1000;
    &:hover {
        &::after {
            background-color: rgba(135, 206, 250);
            transition: background-color 0.15s 0.5s;
        }
    }
    &::after {
        position: absolute;
        content: ' ';
        width: 4px;
        top: 0;
        bottom: 0;
    }
}
</style>
