<template>
    <div class="nav-menu">
        <div>
            <div class="nav-menu__logo">
                <RouterLink class="nav-menu__logo-src" to="/"></RouterLink>
            </div>
            <div class="nav-menu__header">
                <VButton class="button button_green button_fluid prevent-close" @click="showRequestCreateModal"
                    >Создать обращение</VButton
                >
            </div>
            <div class="nav-menu__list">
                <ul>
                    <template v-for="(item, index) in items">
                        <li :key="index" class="nav-menu__item" :class="item.class">
                            <div class="nav-menu__devider" v-show="index === 3"></div>
                            <RouterLink :to="item.to" class="nav-menu__item-link" nav-menu>
                                <component :is="item.icon"></component>
                                <span class="nav-menu__item-label">
                                    {{ item.label }}
                                    <span v-show="item.counter" class="nav-menu__item-label-counter">{{
                                        item.counter
                                    }}</span>
                                </span>
                            </RouterLink>
                            <div class="nav-menu__devider" v-show="index === 3"></div>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <div class="nav-menu__user-list">
            <div class="nav-menu__item-link-user-details" v-show="toggleDetailsStatus">
                <UserDetails :account="$_account" v-show="toggleDetailsStatus"></UserDetails>
            </div>
            <div class="nav-menu__item-link" @click.stop="showDetails">
                <UserCard :account="$_account" />
            </div>
        </div>
    </div>
</template>
<script>
// Components
import VButton from '../../components/VButton/VButton.vue';
import UserCard from '@/views/components/VUser/UserCard.vue';
import UserDetails from '@/views/components/VUser/UserDetails.vue';
import ModalNames from '@/js/enums/ModalNames';

//SVG
import IncomingRequestsSVG from '@/images/incoming_requests.svg';
import OutcomingRequestsSVG from '@/images/outcoming_requests.svg';
import ArchiveSVG from '@/images/archive.svg';
import AllRequestsSVG from '@/images/all_requests.svg';
import SettingsSVG from '@/images/settings.svg';
import LogoutSVG from '@/images/logout.svg';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import oidc from '../../../vue-oidc-client';

const Workspace = namespace('workspace');

@Component({
    components: {
        VButton,
        UserCard,
        UserDetails,
        SettingsSVG,
        LogoutSVG,
    },
})
class NavMenu extends Vue {
    @Workspace.State menuInfo;
    @Workspace.State accessSecurityGroups;

    toggleDetailsStatus = false;

    logout() {
        oidc.signOut();
    }

    get items() {
        const items = [
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'inbox',
                    },
                },
                icon: IncomingRequestsSVG,
                label: 'Входящие',
                counter: this.menuInfo?.inbox ?? 0,
            },
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'outbox',
                    },
                },
                icon: OutcomingRequestsSVG,
                label: 'Исходящие',
                counter: this.menuInfo?.outbox ?? 0,
            },
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'archive',
                    },
                },
                icon: ArchiveSVG,
                label: 'Архив',
            },
        ];

        if (this.$_isExecutor || this.$_isAdmin) {
            items.push({
                to: {
                    name: 'request',
                    params: {
                        method: 'store',
                    },
                },
                icon: AllRequestsSVG,
                label: 'Всe обращения',
                class: 'nav-menu__item_space-top',
            });
        }

        const groups = this.accessSecurityGroups ?? {};

        if (groups) {
            let groupKeys = [];

            if (this.menuInfo?.groups) {
                groupKeys = Object.keys(this.menuInfo.groups);
            }

            groups.forEach((group, index) => {
                let counter = 0;

                if (groupKeys.find((item) => item == group.id)) {
                    counter = this.menuInfo.groups[group.id];
                }

                const item = {
                    to: {
                        name: 'request',
                        params: {
                            method: group.id,
                        },
                    },
                    icon: AllRequestsSVG,
                    label: group.name,
                    counter: counter,
                    class: index === 0 ? 'nav-menu__item_space-top' : '',
                };

                items.push(item);
            });
        }

        return items;
    }

    async showRequestCreateModal() {
        const request = await this.$showModal(ModalNames.REQUEST_CREATE);

        this.$router.push({
            name: 'request',
            params: {
                method: 'outbox',
                id: request.id,
            },
        });
    }

    toggleDetails() {
        this.toggleDetailsStatus = !this.toggleDetailsStatus;
    }

    showDetails() {
        if (!this.toggleDetailsStatus) this.toggleDetailsStatus = true;
    }

    hideDetails() {
        if (this.toggleDetailsStatus) this.toggleDetailsStatus = false;
    }

    mounted() {
        document.addEventListener('click', this.hideDetails);
    }

    destroyed() {
        document.removeEventListener('click', this.hideDetails);
    }
}

export default NavMenu;
</script>
<style lang="scss">
.router-link-active[nav-menu] {
    background: #f1f6fe;
}

::-webkit-scrollbar {
    width: 7px;
    border-radius: 12px;
}
::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 12px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
.nav-menu {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    justify-content: space-between;
    display: flex;
    flex-direction: column;
    &__list {
        max-height: 55vh;
        margin: 0 10px;
    }

    &__header {
        margin: 0 10px 14px 10px;
        .button {
            font-size: 16px;
            border-radius: 10px;
        }
    }

    &__item {
        width: 100%;
        height: auto;
        min-height: 40px;
        margin-bottom: 4px;
        font-size: 1rem;
        color: var(--text-black);
    }

    &__item-link {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: 40px;
        padding: 0 16px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;

        & svg {
            width: 24px;
            height: 24px;
            color: #484848;
        }
        &:hover {
            background: #f8f8f8;
        }

        .user-card {
            height: auto;
            width: inherit;
            display: flex;
            justify-content: center;
            &__label {
                white-space: nowrap;
                overflow: hidden;
                text-align: center;
            }
            &__label-name {
                font-size: 1rem;
            }
        }

        &-user-details{
            position: absolute;
        }
    }

    &__item-label {
        margin-left: 8px;
        display: flex;

        &-counter {
            position: absolute;
            right: 0;
            margin-right: 10px;
            background-color: #00a55a;
            border-radius: 12px;
            padding: 0 7px;
            color: #ffffff;
            font-size: 13px;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;
    }

    &__logo-src {
        display: block;
        width: 158px;
        height: 44px;
        background: url(~@/images/talan-logo.svg?url) center center no-repeat;
        background-size: 100%;
    }

    &__devider {
        width: auto;
        border-radius: 1px;
        display: flex;
        justify-content: center;
        margin: 6px 24px;
        border-bottom: 1px var(--border-column-color) solid;
    }

    &__user-list {
        margin: 0px 10px 10px 10px;
    }
}
</style>
